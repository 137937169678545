@import "~bootstrap/scss/bootstrap.scss";
.form-group {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  .col-form-label {
    position: relative;
    top: -3px;
  }
}
body {
  font-family: "Montserrat", sans-serif;
  background-image: linear-gradient(30deg, #ffffff, #fafafa);
  overflow-x: hidden;
}
h1,
h3 {
  color: #505050;
  font-family: "Roboto Condensed", sans-serif;
}
label.col-form-label {
  color: black;
  text-align: right;
  min-width: 200px;
  font-weight: 550;
}
.col-md-2 {
  min-width: 215px;
}
label.col-form-label::after {
  content: ":";
}
.form-group {
  display: flex;
}
.log-out-button {
  position: relative;
  left: 30px;
  top: -5px;
}
.btn-secondary {
  background-color: #eeeeee;
  color: #101010;
  border-color: #cdcdcd;
}
.admin .nav {
  margin-bottom: 2rem;
}
#page {
  padding-top: 5.5em;
}
.main-content {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
h1,
h3 {
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.center {
  height: 10rem;
  width: 10rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  img {
    width: 10rem;
    object-fit: contain;
    object-position: center top;
  }
}
.page-paragraph {
  display: flex;
  flex-direction: row;
}
.page-paragraph-image {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.page-paragraph-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 25px;
  margin-top: 15px;
}

.imageposition-top {
  flex-direction: column;
  .page-paragraph-image {
    width: 100%;
  }
  .page-paragraph-content {
    width: 100%;
  }
}
.imageposition-right {
  flex-direction: row-reverse;
}
@media (min-width: 1000px) {
  .container-front {
    max-width: 1000px;
  }
}
@media (max-width: 600px) {
  .page-paragraph {
    display: block;
  }
  .page-paragraph-image {
    width: 100%;
  }
  .page-paragraph-content {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .container-front {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}
.backgroundcolor-red {
  margin-top: 20px;
  background-color: #e1cbd7;
  background-image: linear-gradient(30deg, #f5eff3, #e1cbd7);
  img {
    padding-left: 20px;
    padding-right: 20px;
  }
  h3 {
    color: #101010;
  }
  h1 {
    color: #101010;
  }
}
.backgroundcolor-green {
  margin-top: 20px;
  background-color: #cdcfcb;
  background-image: linear-gradient(30deg, #f1f3f0, #cdcfcb);
  img {
    padding-left: 20px;
    padding-right: 20px;
  }
  h3 {
    color: #101010;
  }
  h1 {
    color: #101010;
  }
}
.backgroundcolor-green + .backgroundcolor-red,
.backgroundcolor-red + .backgroundcolor-green {
  margin-top: 0;
}
.page-paragraph-container {
  padding-top: 20px;
  padding-bottom: 20px;
}
p,
h1,
h2,
h3,
h4,
h5 {
  padding-left: 15px;
  padding-right: 15px;
}
.admin-container h3,
.admin-container h1 {
  text-align: left;
}
.ProseMirror h3 {
  text-align: center;
}
.set-language {
  height: 30px;
  padding: 0;
  margin: 0;
  div {
    padding: 0;
    margin: 0;
  }
  img {
    max-width: 50px;
    object-fit: contain;
    height: 30px;
  }
}

.pointer {
  cursor: pointer;
}
.border-1 {
  border: 1px solid #d0d0d0;
}
.navbar {
  background-color: #e8d9e2;
  color: black;
  min-height: 2em;
  // @extend .bg-faded;
}
a.page-link {
  cursor: pointer;
}
a.nav-link.active {
  font-weight: 600;
}
a.page-link.disabled {
  color: #b0b0b0 !important;
  cursor: inherit;
}
a.page-link.disabled:hover {
  background-color: white;
  color: #b0b0b0;
}

/* Spinner */
.cssload-loader {
  position: relative;
  left: calc(50% - 31px);
  width: 62px;
  height: 62px;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  perspective: 780px;
}

.cssload-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.cssload-inner.cssload-one {
  left: 0%;
  top: 0%;
  animation: cssload-rotate-one 1.15s linear infinite;
  -o-animation: cssload-rotate-one 1.15s linear infinite;
  -ms-animation: cssload-rotate-one 1.15s linear infinite;
  -webkit-animation: cssload-rotate-one 1.15s linear infinite;
  -moz-animation: cssload-rotate-one 1.15s linear infinite;
  border-bottom: 3px solid rgb(0, 0, 0);
}

.cssload-inner.cssload-two {
  right: 0%;
  top: 0%;
  animation: cssload-rotate-two 1.15s linear infinite;
  -o-animation: cssload-rotate-two 1.15s linear infinite;
  -ms-animation: cssload-rotate-two 1.15s linear infinite;
  -webkit-animation: cssload-rotate-two 1.15s linear infinite;
  -moz-animation: cssload-rotate-two 1.15s linear infinite;
  border-right: 3px solid rgb(0, 0, 0);
}

.cssload-inner.cssload-three {
  right: 0%;
  bottom: 0%;
  animation: cssload-rotate-three 1.15s linear infinite;
  -o-animation: cssload-rotate-three 1.15s linear infinite;
  -ms-animation: cssload-rotate-three 1.15s linear infinite;
  -webkit-animation: cssload-rotate-three 1.15s linear infinite;
  -moz-animation: cssload-rotate-three 1.15s linear infinite;
  border-top: 3px solid rgb(0, 0, 0);
}
.btn:hover {
  cursor: pointer;
}
.striped.odd {
  background-color: #f5f5f2;
}
.highlight:hover {
  background-color: #f2eded;
}
.quantity {
  width: 20px;
}
.btn-xs {
  padding: 0.15rem 0.25rem;
  font-size: 0.675rem;
  border-radius: 0.1rem;
}
nav .nav-link.nav-link.nav-link {
  color: black;
  padding-left: 20px;
  font-size: 18px;
}
.navbar-brand img {
  height: 35px;
}

.frontpage-columns {
  padding-top: 100px;
}
.admin {
  background-color: #fff;
  color: #666;
  .admin-container {
    padding-top: 30px;
    padding-bottom: 300px;
  }
}
.btn-secondary {
  @extend .btn;
}
.btn-default {
  @extend .btn;
  @extend .btn-secondary;
}
.blog {
  padding-top: 50px;
}
h1 {
  margin-bottom: 0.5rem;
  margin-top: 2rem;
}
.product-card {
  background-color: #082e4e78;
  padding: 10px 0 10px 0;
  height: 70px;
}
.fixed-filter-bar {
  position: fixed;
  left: 35px;
  background-color: #082e4e78;
  bottom: 35px;
  top: 100px;
  padding: 20px;
  width: 300px;
}
.full-width-image {
  width: 100%;
  object-fit: contain;
  height: 100%;
  margin-bottom: 20px;
}
.media-list-input {
  align-items: start;
  margin: 5px;
  padding: 5px;
  background-color: #f2eded;
  .close {
    padding-left: 5px;
  }
}
.form-group-inline .form-group {
  display: flex;
}
