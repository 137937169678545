.table-row {
  display: flex;
  width: 100%;
  padding: 10px;
  * {
    flex: 1;
  }
}
.table-head {
  display: flex;
  width: 100%;
  padding: 10px;
  * {
    flex: 1;
  }
}
