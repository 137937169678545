.part-menu-header {
  height: 160px;
  background: rgba(0, 0, 0, 0.5);
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  color: white;
}
.part-menu-header p {
  padding: 0 16px;
  background: none;
}

.part-menu-items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.part-menu-items a {
  display: block;
  color: #000000;
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  border-left: 4px solid rgba(100, 100, 100, 0);
  justify-content: flex-start;
  align-items: center;
  padding-right: 20px;
  font-size: 25px;
}
.part-menu-items a.active {
  background-color: #e1cbd7;
}

.part-menu-items i {
  padding: 12px;
  text-align: center;
  position: relative;
  top: -1px;
}

.part-menu-items a:hover {
  background: rgba(100, 100, 100, 0.25);
  text-decoration: none;
  color: #000000;
}
.part-menu-items a i {
  display: block;
  width: 48px;
  height: 48px;
  margin-right: 8px;
}
.part-menu-items a b {
  display: block;
  font-weight: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
