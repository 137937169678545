.center-first {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.center-first img {
  width: 60%;
  max-height: 60%;
  object-fit: contain;
  object-position: center top;
}
