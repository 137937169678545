.icon-menu {
  position: fixed;
  color: black;
  left: 1.22rem;
  top: 4rem;
}
.icon-menu i {
  font-size: 2.5rem;
  width: 3rem;
  text-align: center;
  margin-top: 2rem;
}
.icon-menu a {
  color: black;
}

.icon-menu-top {
  position: fixed;
  color: black;
  left: 1.2rem;
  top: 0.8rem;
  width: 10%;
}
.icon-menu-top i {
  font-size: 2.5rem;
  width: 3rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  padding: 7px;
  border-radius: 30px;
}

@media screen and (max-width: 400px) {
  .icon-menu {
    position: fixed;
    color: black;
    left: 1rem;
  }
  .icon-menu i {
    font-size: 1.5rem;
    width: 1.5rem;
    text-align: center;
    margin-top: 1rem;
  }
  .icon-menu-top {
    position: fixed;
    color: black;
    left: 0.5rem;
    top: 0.5rem;
    width: 10%;
  }
  .icon-menu-top i {
    font-size: 1.5rem;
    width: 2.5rem;
    text-align: center;
  }
}
